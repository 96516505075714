<template>
  <div>
    <el-row :gutter="10"
            style="padding-bottom:10px">
      <el-col :span="6">
        <el-select style="width:100%"
                   clearable
                   v-model="subject_id"
                   placeholder="选择科目">
          <el-option v-for="item in subjectList"
                     :key="item.subject_id"
                     :label="item.subject_name"
                     :value="item.subject_id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-select style="width:100%"
                   clearable
                   v-model="lecture_turn_id"
                   placeholder="轮次">
          <el-option v-for="item in trunList"
                     :key="item.lecture_turn_id"
                     :label="item.lecture_turn_name"
                     :value="item.lecture_turn_id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-button type="primary"
                   @click="search">确定</el-button>
        <el-button type="primary"
                   @click="add">新增</el-button>
      </el-col>
    </el-row>

    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
export default {
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      subject_id: '',
      lecture_turn_id: '',
      tableData: [],
      subjectList: [],
      trunList: [],
      pageName: 'cz_teachingMaterials_category_admin',
      tableColumns: [
        { prop: 'lecture_category_id', align: 'center', label: '编号', width: '100', showToopic: false },
        { prop: 'lecture_category_name', align: 'center', label: '名称', width: '', showToopic: true },
        { prop: 'subject_name', align: 'center', label: '科目', width: '', showToopic: false },
        { prop: 'lecture_turn_name', align: 'center', label: '轮次', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '200', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'edit' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ]
    }
  },
  components: {
    Form
  },
  mounted () {
    this.getSubject()
    this.getTurnList()
    this.setTablesColums()
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    getTurnList () {
      this.$http({
        url: '/api/v1/lecture/turn',
        method: 'get',
        params: {
          page: 1,
          limit: 10000
        }
      }).then(res => {
        this.trunList = res.data.list
      })
    },
    initData (page, limit) {
      this.tableLoading = true
      this.$http({
        url: '/api/v1/lecture/category',
        method: 'get',
        params: {
          page,
          limit,
          subject_id: this.subject_id,
          lecture_turn_id: this.lecture_turn_id
        }
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    getSubject () {
      this.$http({
        url: '/api/v1/papers/subject',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.subject
        this.subject_id = this.subjectList[0].subject_id
        this.search()
      })
    },
    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/lecture/del_category',
          method: 'post',
          data: {
            lecture_category_id: row.lecture_category_id
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        this.search()
      })
    },
    edit (row) {
      let form = JSON.parse(JSON.stringify(row))
      this.$refs.FormRef.getSubjectList()
      this.$refs.FormRef.text = '修改'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    add () {
      let form = {
        lecture_category_name: '',
        lecture_turn_id: '',
        subject_id: ''
      }
      this.$refs.FormRef.form = form
      this.$refs.FormRef.getSubjectList()
      this.$refs.FormRef.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
</style>
